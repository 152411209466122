<template>
  <b-row>
    <b-col
      cols="12"
      md="7"
    >
      <b-overlay
        :show="showFormOverlay"
        rounded="sm"
      >
        <b-card title="Premiecalculatie configuratie">
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-form
              @submit.prevent="handleSubmit(fetchPremiumCalculationForProduct)"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="productID"
                    rules=""
                  >
                    <b-form-group
                      label="Product"
                      label-for="productID"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="productOptions"
                        label="name"
                        :clearable="false"
                        @input="setCalculationMethodID"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="requiredFields.some(r => r === 'additionalInsuranceType')"
                  cols="12"
                  md="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="additionalInsuranceType"
                    rules="required"
                  >
                    <b-form-group
                      label="Wilt u een partner of kind(eren) mee verzekeren?"
                      label-for="additionalInsuranceType"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="calculationData.additionalInsuranceType"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="additionalInsuranceTypeOptions"
                        label="label"
                        :reduce="val => val.value"
                        :clearable="true"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="requiredFields.some(r => r === 'premiumAllowancePercentage')"
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="premiumAllowancePercentage"
                    rules="required|integer|between:0,100"
                  >
                    <b-form-group
                      label="Medisch toeslag (%)"
                      label-for="premiumAllowancePercentage"
                    >
                      <b-form-input
                        id="premiumAllowancePercentage"
                        v-model="calculationData.premiumAllowancePercentage"
                        :state="getValidationState(validationContext)"
                        type="number"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="requiredFields.some(r => r === 'currency')"
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Valuta"
                    label-for="currency"
                  >
                    <div class="demo-inline-spacing">
                      <b-form-radio
                        v-model="calculationData.currency"
                        name="currency"
                        value="SRD"
                        class="mt-0"
                        @click="setMinMaxInsuredAmount"
                      >
                        SRD
                      </b-form-radio>
                      <b-form-radio
                        v-model="calculationData.currency"
                        name="currency"
                        value="USD"
                        class="mt-0"
                        @click="setMinMaxInsuredAmount"
                      >
                        USD
                      </b-form-radio>
                      <b-form-radio
                        v-model="calculationData.currency"
                        name="currency"
                        value="EUR"
                        class="mt-0"
                        @click="setMinMaxInsuredAmount"
                      >
                        EUR
                      </b-form-radio>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="requiredFields.some(r => r === 'insuredAmount')"
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="insuredAmount"
                    :rules="`required|integer|maxValue:${maxInsuredAmount}|minValue:${minInsuredAmount}`"
                  >
                    <b-form-group
                      label="Verzekerd kapitaal / Jaarloon"
                      label-for="insuredAmount"
                    >
                      <b-form-input
                        id="insuredAmount"
                        v-model="calculationData.insuredAmount"
                        :state="getValidationState(validationContext)"
                        type="number"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="requiredFields.some(r => r === 'mainAge')"
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="mainAge"
                    rules="required|integer|between:0,200"
                  >
                    <b-form-group
                      label="Leeftijd hoofdverzekerde"
                      label-for="mainAge"
                    >
                      <b-form-input
                        id="mainAge"
                        v-model="calculationData.mainAge"
                        v-debounce:500ms="setDuration"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="requiredFields.some(r => r === 'mainGender')"
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="updateGender"
                    rules="required"
                  >
                    <label>Geslacht hoofdverzekerde</label>
                    <div class="demo-inline-spacing">
                      <b-form-radio
                        v-model="calculationData.mainGender"
                        name="updateGender"
                        value="m"
                        class="mt-0"
                        :state="getValidationState(validationContext)"
                      >
                        Man
                      </b-form-radio>
                      <b-form-radio
                        v-model="calculationData.mainGender"
                        name="updateGender"
                        value="f"
                        class="mt-0"
                        :state="getValidationState(validationContext)"
                      >
                        Vrouw
                      </b-form-radio>
                    </div>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="requiredFields.some(r => r === 'partnerPensionType') && (calculationData.additionalInsuranceType === 1 || calculationData.additionalInsuranceType === 2 || calculationData.additionalInsuranceType === 3)"
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="partnerPensionType"
                    rules=""
                  >
                    <b-form-group
                      label="Partner verzekeringstype"
                      label-for="partnerPensionType  "
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="calculationData.partnerPensionType"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="partnerInsuranceTypeOptions"
                        :reduce="val => val.value"
                        :clearable="true"
                        input-id="partnerPensionType "
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="requiredFields.some(r => r === 'subAge') && (calculationData.calculationMethodID === 2 || calculationData.calculationMethodID === 4 && (calculationData.additionalInsuranceType === 1 || calculationData.additionalInsuranceType === 3))"
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="subAge"
                    rules="integer"
                  >
                    <b-form-group
                      label="Leeftijd meeverzekerde"
                      label-for="subAge"
                    >
                      <b-form-input
                        id="subAge"
                        v-model="calculationData.subAge"
                        v-debounce:500ms="setDuration"
                        :state="getValidationState(validationContext)"
                        type="number"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="requiredFields.some(r => r === 'subGender') && (calculationData.calculationMethodID === 2 || (calculationData.calculationMethodID === 4 && (calculationData.additionalInsuranceType === 1 || calculationData.additionalInsuranceType === 3)))"
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="subGender"
                    rules="required"
                  >
                    <label>Geslacht meeverzekerde</label>
                    <div class="demo-inline-spacing">
                      <b-form-radio
                        v-model="calculationData.subGender"
                        name="subGender"
                        value="m"
                        class="mt-0"
                        :state="getValidationState(validationContext)"
                      >
                        Man
                      </b-form-radio>
                      <b-form-radio
                        v-model="calculationData.subGender"
                        name="subGender"
                        value="f"
                        class="mt-0"
                        :state="getValidationState(validationContext)"
                      >
                        Vrouw
                      </b-form-radio>
                    </div>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="requiredFields.some(r => r === 'employerContributionPercentage')"
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="employerContributionPercentage"
                    rules="required|integer|between:50,100"
                  >
                    <b-form-group
                      label="Werkgeversbijdrage (%)"
                      label-for="employerContributionPercentage"
                    >
                      <b-form-input
                        id="employerContributionPercentage"
                        v-model="calculationData.employerContributionPercentage"
                        type="number"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="requiredFields.some(r => r === 'contractDuration')"
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="contractDuration"
                    rules="required"
                  >
                    <b-form-group
                      label="Looptijd"
                      label-for="contractDuration"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="calculationData.contractDuration"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="durationOptions"
                        label="name"
                        :clearable="true"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="requiredFields.some(r => r === 'startDate')"
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="startDate"
                    rules="required|date"
                  >
                    <b-form-group
                      label="Startdatum"
                      label-for="startDate"
                      :state="getValidationState(validationContext)"
                    >
                      <flat-pickr
                        id="claimDateOfDeath"
                        v-model="calculationData.startDate"
                        :config="startDateConfig"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mt-2 mr-1"
                    type="submit"
                    :disabled="showCalculateSpinner"
                  >
                    <b-spinner
                      v-if="showCalculateSpinner"
                      small
                      class="mr-1"
                    />
                    Berekenen
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-overlay>
    </b-col>
    <b-col
      cols="12"
      md="5"
    >
      <b-overlay
        :show="showCalculateSpinner"
        rounded="sm"
      >
        <b-card title="Premieinformatie">
          <b-row>

            <!-- User Info: Left col -->
            <b-col
              cols="12"
              xl="12"
            >
              <table
                v-if="calculationResponse"
                class="mt-2 mt-xl-0 w-100"
              >
                <tr
                  v-if="calculationResponse.premiumFactor && calculationResponse.productType === 1"
                >
                  <th class="pb-50">

                    <span class="font-weight-bold">Premiefactor</span>

                  </th>
                  <td class="pb-50">
                    {{ calculationResponse.premiumFactor }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <span class="font-weight-bold">Medische toeslag (%)</span>
                  </th>
                  <td class="pb-50">
                    {{ calculationResponse.allowancePercentage ? calculationResponse.allowancePercentage : '0' }}%
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <span class="font-weight-bold">Medische toeslag ({{ calculationData.currency }})</span>
                  </th>
                  <td class="pb-50">
                    {{ calculationData.currency }} {{ calculationResponse.medicalAllowance ? parseFloat(calculationResponse.medicalAllowance).toFixed(2) : '0.00' }}
                  </td>
                </tr>
                <tr
                  v-if="calculationData.calculationMethodID === 4"
                >
                  <th class="pb-50">

                    <span class="font-weight-bold">Latent AP</span>
                  </th>
                  <td class="pb-50">
                    {{ calculationData.currency }}  {{ parseFloat(calculationResponse.yearLatentAP).toFixed(2) }}
                  </td>
                </tr>
                <tr
                  v-if="calculationData.calculationMethodID === 4"
                >
                  <th class="pb-50">

                    <span class="font-weight-bold">Latent IP</span>
                  </th>
                  <td class="pb-50">
                    {{ calculationData.currency }}  {{ parseFloat(calculationResponse.yearLatentIP).toFixed(2) }}
                  </td>
                </tr>
                <tr
                  v-if="calculationData.calculationMethodID === 4"
                >
                  <th class="pb-50">

                    <span class="font-weight-bold">Latent PP</span>
                  </th>
                  <td class="pb-50">
                    {{ calculationData.currency }}  {{ parseFloat(calculationResponse.yearLatentPP).toFixed(2) }}
                  </td>
                </tr>
                <tr
                  v-if="calculationData.calculationMethodID === 4"
                >
                  <th class="pb-50">

                    <span class="font-weight-bold">Latent WZ</span>
                  </th>
                  <td class="pb-50">
                    {{ calculationData.currency }} {{ parseFloat(calculationResponse.yearLatentWZ).toFixed(2) }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <span class="font-weight-bold">Korting (%)</span>
                  </th>
                  <td class="pb-50">
                    {{ calculationResponse.discountPercentage ? calculationResponse.discountPercentage : '0' }}%
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">

                    <span class="font-weight-bold">Korting ({{ calculationData.currency }})</span>
                  </th>
                  <td class="pb-50">
                    {{ calculationData.currency }} {{ parseFloat((calculationResponse.discount + calculationResponse.extraDiscount)).toFixed(2) }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">

                    <span class="font-weight-bold">Poliskosten</span>
                  </th>
                  <td class="pb-50">
                    {{ calculationData.currency }} {{ parseFloat(calculationResponse.policyCost).toFixed(2) }}
                  </td>
                </tr>
                <tr
                  v-if="calculationResponse.grossPremium"
                >
                  <th class="pb-50">

                    <span class="font-weight-bold">Bruto Premie</span>
                  </th>
                  <td class="pb-50">
                    {{ calculationData.currency }} {{ parseFloat(calculationResponse.grossPremium).toFixed(2) }}
                  </td>
                </tr>
                <tr
                  v-if="calculationResponse.premium"
                >
                  <th class="pb-50">

                    <span class="font-weight-bold">Premie</span>
                  </th>
                  <td class="pb-50">
                    {{ calculationData.currency }} {{ parseFloat(calculationResponse.premium).toFixed(2) }}
                  </td>
                </tr>
              </table>
              <b-alert
                variant="warning"
                :show="true"
                class="mb-1 mt-1"
              >
                <div class="alert-body">
                  <feather-icon
                    icon="InfoIcon"
                    class="mr-50"
                  />
                  Selecteer een product en vul alle productcalculatie details in, klik hierna op berekenen om de premieinformatie te zien.<br><br>
                  Let op: Deze premiecalculatie is indicatief. Er kunnen geen rechten aan ontleend worden.
                </div>
              </b-alert>
            </b-col>
          </b-row>
        </b-card>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import productsStoreModule from '@/views/pages/products/productsStoreModule'
import { onUnmounted } from '@vue/composition-api'
import {
  BAlert,
  BButton,
  BCard, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormRadio, BOverlay, BRow, BSpinner,
} from 'bootstrap-vue'
import {
  integer,
  required,
  between,
  date,
} from '@core/utils/validations/validations'
import vSelect from 'vue-select'
import useProductsList from '@/views/pages/products/useProductsList'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getDirective } from 'vue-debounce'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    ValidationObserver,
    ValidationProvider,
    BFormInvalidFeedback,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormRadio,
    BSpinner,
    BAlert,
    BOverlay,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
    debounce: getDirective(),
  },
  data() {
    return {
      productOptions: [],
      calculationData: {
        productID: null,
        calculationMethodID: null,
        mainAge: null,
        subAge: null,
        insuredAmount: null,
        contractDuration: null,
        currency: 'SRD',
        partnerPensionType: null,
        premiumAllowancePercentage: null,
        additionalInsuranceType: 0,
        mainGender: null,
        subGender: null,
        employerContributionPercentage: null,
        startDate: null,
      },
      calculationResponse: null,
      showCalculateSpinner: false,
      showFormOverlay: false,
      requiredFields: [],
      durationOptions: [],
      showDurationSpinner: false,
      startDateConfig: {
        minDate: 'today',
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
      },
      maxInsuredAmount: 0,
      minInsuredAmount: 0,
      selectedMethode: null,
    }
  },
  beforeMount() {
    this.fetchProducts()
  },
  methods: {
    setDuration() {
      if (this.requiredFields.some(r => r === 'contractDuration')) {
        if (!this.calculationData.mainAge) {
          this.durationOptions = []
        } else if (!this.calculationData.subAge) {
          this.fetchMaxDurationByAges(this.calculationData.mainAge)
        } else {
          this.fetchMaxDurationByAges(`${this.calculationData.mainAge},${this.calculationData.subAge}`)
        }
      }
    },
    fetchPremiumCalculationForProduct() {
      this.calculationResponse = null
      this.showCalculateSpinner = true
      store.dispatch('app-premium-calculator/previewCalculation', this.calculationData)
        .then(response => {
          this.calculationResponse = response.data
          this.showCalculateSpinner = false
        })
        .catch(() => {
          this.showCalculateSpinner = false
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error bij het berekenen van de premie.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    setMinMaxInsuredAmount() {
      switch (this.calculationData) {
        case 'SRD':
          this.maxInsuredAmount = this.selectedMethode.maxInsuredAmountSRD
          this.minInsuredAmount = this.selectedMethode.minInsuredAmountSRD
          break
        case 'USD':
          this.maxInsuredAmount = this.selectedMethode.maxInsuredAmountUSD
          this.minInsuredAmount = this.selectedMethode.minInsuredAmountUSD
          break
        case 'EUR':
          this.maxInsuredAmount = this.selectedMethode.maxInsuredAmountEUR
          this.minInsuredAmount = this.selectedMethode.minInsuredAmountEUR
          break
        default:
          this.maxInsuredAmount = this.selectedMethode.maxInsuredAmountSRD
          this.minInsuredAmount = this.selectedMethode.minInsuredAmountSRD
      }
    },
    setCalculationMethodID(data) {
      this.selectedMethode = data
      this.calculationResponse = null
      // this.maxInsuredAmount = 100000000000
      // // eslint-disable-next-line radix
      // if (parseInt(data.calculationMethodID) === 6) {
      //   this.maxInsuredAmount = 70000
      // }
      this.setMinMaxInsuredAmount()

      this.calculationData = {
        productID: data.id,
        calculationMethodID: data.calculationMethodID,
        mainAge: null,
        subAge: null,
        insuredAmount: null,
        contractDuration: null,
        partnerPensionType: null,
        premiumAllowancePercentage: null,
        additionalInsuranceType: 0,
        mainGender: null,
        subGender: null,
        employerContributionPercentage: null,
        currency: 'SRD',
      }

      this.$refs.refFormObserver.reset()

      const object = this.premiumCalculatorRequiredParamsPerProduct.find(c => c.calculationMethodID === data.calculationMethodID)

      if (object) {
        this.requiredFields = object.required
      } else {
        this.requiredFields = []
      }
    },
    fetchProducts() {
      this.showFormOverlay = true
      store.dispatch('app-premium-calculator/fetchProducts', { sortDesc: false, sortBy: 'name', active: 1 })
        .then(response => {
          this.productOptions = response.data.items
          this.showFormOverlay = false
        })
        .catch(() => {
          this.showFormOverlay = false
        })
    },
    fetchMaxDurationByAges(ages) {
      this.showDurationSpinner = true
      store.dispatch('app-premium-calculator/fetchMaxDurationByAges', { productID: this.calculationData.productID, ages })
        .then(response => {
          this.durationOptions = []

          this.contractDurationOptions = []
          const minDuration = response.data.minDuration ? response.data.minDuration : response.data.maxDuration
          for (let i = 0; i < response.data.maxDuration; i += 1) {
            if ((i + 1) >= minDuration) {
              this.durationOptions.push(i + 1)
            }
          }

          this.showDurationSpinner = false
        })
        .catch(() => {
          this.showDurationSpinner = false
        })
    },
  },
  setup() {
    const PREMIUM_CALCULATOR_APP_STORE_MODULE_NAME = 'app-premium-calculator'

    // Register module
    if (!store.hasModule(PREMIUM_CALCULATOR_APP_STORE_MODULE_NAME)) store.registerModule(PREMIUM_CALCULATOR_APP_STORE_MODULE_NAME, productsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PREMIUM_CALCULATOR_APP_STORE_MODULE_NAME)) store.unregisterModule(PREMIUM_CALCULATOR_APP_STORE_MODULE_NAME)
    })

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    const {
      premiumCalculatorRequiredParamsPerProduct,
      partnerInsuranceTypeOptions,
      additionalInsuranceTypeOptions,
    } = useProductsList()

    return {
      premiumCalculatorRequiredParamsPerProduct,
      refFormObserver,
      getValidationState,
      partnerInsuranceTypeOptions,
      additionalInsuranceTypeOptions,
      required,
      integer,
      between,
      date,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

import axios from '@axios'
import axiosDefaultConfig from '@core/app-config/axiosDefaultConfig'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProducts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}products`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProduct(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}products/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addProduct(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}products`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateProducts(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${axiosDefaultConfig.backendEndPoint}products/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteProducts(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${axiosDefaultConfig.backendEndPoint}products/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProductCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}productcategories?fields=id,name`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    previewCalculation(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}previewcalculation`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMaxDurationByAges(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}products/getmaxdurationbyages?productID=${data.productID}&ages=${data.ages}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}

import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useProductsList() {
  // Use toast
  const toast = useToast()

  const refProductsListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { label: '#', key: 'id', sortable: true },
    { label: 'Naam', key: 'name', sortable: true },
    { label: 'Omschrijving', key: 'description', sortable: true },
    { label: 'Aanmaakdatum', key: 'createTimestamp', sortable: true },
    { label: 'Acties', key: 'actions', sortable: false },
  ]
  const perPage = ref(10)
  const totalProducts = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(false)
  const showOverlay = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refProductsListTable.value ? refProductsListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProducts.value,
    }
  })

  const refetchProductsData = () => {
    refProductsListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchProductsData()
  })

  const fetchProducts = (ctx, callback) => {
    store
      .dispatch('apps-products-list/fetchProducts', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortDesc: isSortDirDesc.value,
        sortBy: sortBy.value,
      })
      .then(response => {
        const { items, _meta } = response.data

        callback(items)
        showOverlay.value = false
        totalProducts.value = _meta.totalCount
      })
      .catch(() => {
        showOverlay.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching product category list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const statusOptions = [
    { label: 'Inactief', value: 0 },
    { label: 'Actief', value: 1 },
  ]

  const calculationMethodOptions = [
    { label: 'TOR 1', value: 1 },
    { label: 'TOR 1 V2', value: 5 },
    { label: 'TOR 2', value: 2 },
    { label: 'Uitvaartverzekering', value: 3 },
    { label: 'Uitvaartverzekering V2', value: 6 },
    { label: 'APSR', value: 4 },
  ]

  const yesOrNoOptions = [
    { label: 'Nee', value: 0 },
    { label: 'Ja', value: 1 },
  ]

  const prolongationTypeOptions = [
    { label: 'Normaal', value: 1 },
    { label: 'Afkoop', value: 2 },
    { label: 'Dalend', value: 3 },
  ]

  const documentPolisOptions = [
    { label: 'TOR', value: 1 },
    { label: 'Uitvaartverzekering', value: 6 },
    { label: 'APSR', value: 7 },
  ]

  const documentOfferOptions = [
    { label: 'TOR', value: 3 },
    { label: 'APSR', value: 8 },
    { label: 'Uitvaartverzekering', value: 9 },
  ]

  const formsOptions = [
    { label: 'Medisch', value: 1 },
  ]

  const formCalculationOptions = [
    { label: 'Medisch + TOR', value: 1 },
    { label: 'Medisch + BFS', value: 2 },
  ]

  const hasDurationLimitByAgeOptions = [
    { label: 'Nee', value: 0 },
    { label: 'Ja', value: 1 },
  ]

  const productTypeOptions = [
    { label: 'TOR & Uitvaartverzekering', value: 1 },
    { label: 'APSR', value: 2 },
  ]

  const partnerInsuranceTypeOptions = [
    { label: '10 jaar', value: 2 },
    { label: 'Lifetime', value: 1 },
  ]

  const additionalInsuranceTypeOptions = [
    { label: 'Geen', value: 0 },
    { label: 'Partner', value: 1 },
    { label: 'Kind(eren)', value: 2 },
    { label: 'Beide', value: 3 },
  ]

  const premiumCalculatorRequiredParamsPerProduct = [
    {
      products: ['TOR op 1 leven gelijkblijvend jaarpremie', 'TOR op 1 leven gelijkblijvend meerjarige premie', 'TOR op 1 leven gelijkmatig dalend'],
      calculationMethodID: 1,
      required: [
        'mainAge',
        'mainGender',
        'insuredAmount',
        'currency',
        'contractDuration',
        'premiumAllowancePercentage',
      ],
    },
    {
      products: ['TOR op 2 levens'],
      calculationMethodID: 2,
      required: [
        'mainAge',
        'mainGender',
        'subAge',
        'subGender',
        'insuredAmount',
        'currency',
        'contractDuration',
        'premiumAllowancePercentage',
      ],
    },
    {
      products: ['Uitvaartverzekering'],
      calculationMethodID: 3,
      required: [
        'mainAge',
        'mainGender',
        'insuredAmount',
        'currency',
        'contractDuration',
        'premiumAllowancePercentage',
      ],
    },
    {
      products: ['APSR'],
      calculationMethodID: 4,
      required: [
        'mainAge',
        'insuredAmount',
        'mainGender',
        'subAge',
        'subGender',
        'currency',
        'partnerPensionType',
        'premiumAllowancePercentage',
        'additionalInsuranceType',
        'employerContributionPercentage',
        'startDate',
      ],
    },
    {
      products: ['TOR op 1 leven gelijkblijvend jaarpremie V2'],
      calculationMethodID: 5,
      required: [
        'mainAge',
        'mainGender',
        'insuredAmount',
        'currency',
        'contractDuration',
        'premiumAllowancePercentage',
      ],
    },
    {
      products: ['Uitvaartverzekering V2'],
      calculationMethodID: 6,
      required: [
        'mainAge',
        'mainGender',
        'insuredAmount',
        'currency',
        'contractDuration',
        'premiumAllowancePercentage',
      ],
    },
  ]

  return {
    fetchProducts,
    tableColumns,
    perPage,
    currentPage,
    totalProducts,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    refProductsListTable,
    refetchProductsData,
    statusOptions,
    calculationMethodOptions,
    yesOrNoOptions,
    prolongationTypeOptions,
    documentPolisOptions,
    documentOfferOptions,
    formsOptions,
    formCalculationOptions,
    isSortDirDesc,
    showOverlay,
    hasDurationLimitByAgeOptions,
    productTypeOptions,
    partnerInsuranceTypeOptions,
    premiumCalculatorRequiredParamsPerProduct,
    additionalInsuranceTypeOptions,
  }
}
